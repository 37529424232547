import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import ProcurementBids from "./screens/Procurement/ProcurementBids.jsx"; 
import AboutUs from "./components/Sections/AboutUs.jsx";
import Login from "./components/auth/Login.jsx";
import SelectedArticle from "components/ClickedViews/SelectedArticle.jsx";
import SelectedProject from "components/ClickedViews/SelectedProject.jsx";
import ProjectsPage from "screens/Projects/ProjectsPage.jsx";
 
import TopNavbar from "./components/Nav/TopNavbar"; 
import ProcurementAwards from "./screens/Procurement/ProcurementAwards.jsx";
import Organization from "./screens/OrganizationManagement.jsx";
import BlogPage from "./screens/MediaReleases/BlogPage.jsx";
import AboutUsPage from "screens/AboutUsPage.jsx";
import OrganizationManagement from "./screens/OrganizationManagement.jsx";
import ContactUsPage from "screens/Contact/ContactUsPage.jsx";
import Footer from "components/Sections/Footer.jsx";
import styled from 'styled-components';
import GeneralConditionsPage from "screens/Procurement/GeneralConditions.jsx";
import ProcurementLegislationPage from "screens/Procurement/ProcurementLegislation.jsx";
import ProcurementPlanPage from "screens/Procurement/ProcurementPlan.jsx";
import AboutMission from "screens/About/Mission.jsx";
import PartnersSponsors from "screens/About/Partners.jsx";
import PhotoGallery from "screens/MediaReleases/PhotoGallery.jsx";
import VideoGallery from "screens/MediaReleases/VideoGallery.jsx";
import DownloadsPage from "screens/Downloads/DownloadsPage.jsx";
import GettingFunded from "screens/Projects/GettingFunded.jsx";
import FundingGuidelines from "screens/Projects/FundingGuidelines.jsx";
import Vacancies from "screens/Notices/Vacancies.jsx";
import BursariesPage from "screens/Notices/Bursaries.jsx";
import CompletedProjectsPage from "screens/Projects/CompletedProjects.jsx";
import FundedAlready from "screens/Projects/FundedAlready.jsx";
import OurHistory from "screens/About/OurHistory.jsx";
import OurValues from "screens/About/Values.jsx";
import Board from "screens/About/Board.jsx";
import ExecutiveCommitte from "screens/About/ExecutiveCommitte.jsx";
import Staff from "screens/About/Staff.jsx";
import FAQPage from "screens/About/FAQs.jsx";
import DonatePage from "screens/Donate/Donate.jsx";
import Aside from "components/Sections/Aside.jsx";
import TechnicalAdvisory from "screens/About/TechnicalAdvisory.jsx";
import FinanceAudit from "screens/About/FinanceRiskAudit.jsx";
import UsefulLinks from "screens/Notices/UsefulLinks.jsx";
import Disclaimer from "screens/About/Disclaimer.jsx";
import OrganizationalChart from "screens/About/OrganizationalChart.jsx";



import CS from "./gptf.png";


export default function App() {
  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <AppWrapper>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <TopNavbar isLandingPage={isLandingPage}  />
      <ContentWrapper>
        <Routes>
          <Route path="/" element={<Landing />} /> 
          <Route path="/login" element={<Login />} /> 
          <Route path="/about-mission" element={<AboutMission />} />
          <Route path="/about-partners" element={<PartnersSponsors />} />
          <Route path="/procurement-bids" element={<ProcurementBids />} /> 
          <Route path="/procurement-awards" element={<ProcurementAwards />} />
          <Route path="/organization-management" element={<OrganizationManagement />} />
          <Route path="/blogs" element={<BlogPage />} /> 
          <Route path="/photo-gallery" element={<PhotoGallery />} />
          <Route path="/video-gallery" element={<VideoGallery />} />
          <Route path="/downloads" element={<DownloadsPage />} />
          <Route path="/article/:id" element={<SelectedArticle />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/project/:id" element={<SelectedProject />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/procurement-conditions" element={<GeneralConditionsPage />} />
          <Route path="/procurement-legislation" element={<ProcurementLegislationPage />} />
          <Route path="/procurement-plan" element={<ProcurementPlanPage />} />
          <Route path="/getting-funded" element={<GettingFunded />} />
          <Route path="/funding-guidelines" element={<FundingGuidelines />} />
          <Route path="/vacancies" element={<Vacancies />} />
          <Route path="/bursaries" element={<BursariesPage />} />
          <Route path="/completed-projects" element={<CompletedProjectsPage />} />
          <Route path="/funded-projects" element={<FundedAlready />} />
          <Route path="/about-history" element={<OurHistory />} />
          {/* <Route path="/about-values" element={<OurValues />} /> */}
          <Route path="/useful-links" element={<UsefulLinks />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          {/* <Route path="/board-of-directors" element={<Board />} /> */}
          {/* <Route path="/executive-committee" element={<ExecutiveCommitte />} /> */}
          {/* <Route path="/technical-advisory" element={<TechnicalAdvisory />} /> */}
          {/* <Route path="/risk-audit" element={<FinanceAudit />} /> */}
          {/* <Route path="/staff" element={<Staff />} /> */}
          <Route path="/organogram" element={<OrganizationalChart /> } />
          <Route path="/faqs" element={<FAQPage />} />
          {/* <Route path="/support-us" element={<DonatePage />} /> */}
        </Routes>
      </ContentWrapper>
      <Aside />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
`;

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  background-color: #333;
`;