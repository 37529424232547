import React from 'react';
import styled from 'styled-components';

// // Import images
// import Man1 from "assets/img/team/man-team-1.avif";
// import Man2 from "assets/img/team/man-team-2.avif";
// import Man3 from "assets/img/team/man-team-3.avif";
// import Woman1 from "assets/img/team/woman-team-1.avif";
// import Woman2 from "assets/img/team/woman-team-2.avif";
// import Woman3 from "assets/img/team/woman-team-3.avif";

import Man1 from "assets/img/team/team-member-paceholder.avif";
import Man2 from "assets/img/team/team-member-paceholder.avif";
import Man3 from "assets/img/team/team-member-paceholder.avif";
import Woman1 from "assets/img/team/team-member-paceholder.avif";
import Woman2 from "assets/img/team/team-member-paceholder.avif";
import Woman3 from "assets/img/team/team-member-paceholder.avif";

const DepartmentalCharts = () => {
  const renderOrgNode = (node) => {
    return (
      <li key={node.name}>
        <div className="org-chart__item">
          <div className="org-chart__content">
            {node.image && (
              <div className="image-wrapper">
                <img src={node.image} alt={node.name} />
              </div>
            )}
            <p className="name">{node.name}</p>
            <p className="title">{node.title}</p>
          </div>
        </div>
        {node.children && node.children.length > 0 && (
          <ul>
            {node.children.map(renderOrgNode)}
          </ul>
        )}
      </li>
    );
  };

  return (
    <PageWrapper>
      <ChartSection>
        <h2 style={{ marginTop: "20px" }}>Board of Directors</h2>
        <ChartWrapper>
          <div className="org-chart">
            {renderOrgNode({
              name: "Mr. Colgar Sikopo",
              title: "Chairperson",
              image: Man1,
              children: [{
                name: "Ms. Mclesia Mbaisa",
                title: "Vice Chairperson",
                image: Woman1,
                children: [
                  {
                    name: "Mr. Kandiwapa Amwele",
                    title: "Board Member",
                    image: Man1,
                  },
                  {
                    name: "Ms. Doreen Saisai",
                    title: "Board Member",
                    image: Woman3,
                  },
                  {
                    name: "Mr. Thomas Muronga",
                    title: "Board Member",
                    image: Man1,
                  },
                  {
                    name: "Mr. Frans Kamenye",
                    title: "Fund Manager",
                    image: Man1,
                  },
                ],
              }],
            })}
          </div>
        </ChartWrapper>
      </ChartSection>

      <ChartSection>
        <h2>Executive Committee</h2>
        <ChartWrapper>
          <div className="org-chart">
            {renderOrgNode({
              name: "Mr. Frans Kamenye",
              title: "Fund Manager/CEO",
              image: Man1,
              children: [
                {
                  name: "Mrs. Martha Ndove",
                  title: "Manager: Finance & Administration",
                  image: Woman1,
                },
              ],
            })}
          </div>
        </ChartWrapper>
      </ChartSection>

      <ChartSection>
        <h2>Finance, Risk & Audit Committee</h2>
        <ChartWrapper>
          <div className="org-chart">
            {renderOrgNode({
              name: "Mr. Kandiwapa Amwele",
              title: "Chairperson",
              image: Woman1,
              children: [
                {
                  name: "Ms. Juraney Gaingos",
                  title: "Member",
                  image: Woman2,
                },
                {
                  name: "Mr. Pendukeni Fillippus",
                  title: "Member",
                  image: Woman3,
                },
              ],
            })}
          </div>
        </ChartWrapper>
      </ChartSection>

      <ChartSection>
        <h2>Technical Advisory Committee</h2>
        <ChartWrapper>
          <div className="org-chart">
            {renderOrgNode({
              name: "Mrs. Elly Hamunyela",
              title: "Chairperson",
              image: Woman1,
              children: [
                {
                  name: "Mr. Bennet Kahuure",
                  title: "Member",
                  image: Man1,
                },
                {
                  name: "Mr. Boas Erckie",
                  title: "Member",
                  image: Man2,
                },
                {
                  name: "Mr. Kenneth Uiseb",
                  title: "Member",
                  image: Man3,
                },
              ],
            })}
          </div>
        </ChartWrapper>
      </ChartSection>

      <ChartSection>
        <h2>Staff</h2>
        <ChartWrapper>
          <div className="org-chart">
            {renderOrgNode({
              name: "Mrs. Martha Ndove",
              title: "Manager: Finance & Administration",
              image: Woman1,
              children: [
                {
                  name: "Bestinah Lishoni",
                  title: "Accountant",
                  image: Woman1,
                },
                {
                  name: "Vacant",
                  title: "Human Resources Officer",
                  image: Woman2,
                  children: [
                    {
                      name: "Paulus Kudumo",
                      title: "Hygiene Officer",
                      image: Man1,
                    },
                  ],
                },
                {
                  name: "Merlin Mercia Katjiru",
                  title: "Procurement Officer",
                  image: Woman3,
                },
                {
                  name: "Ester Nambala",
                  title: "Procurement Intern",
                  image: Woman1,
                },
                {
                  name: "Sarti Ananias",
                  title: "Finance Intern",
                  image: Woman2,
                },
                {
                  name: "Wilhellem Fudheni",
                  title: "Human-Wildlife Conflict Management (HWCM) Intern",
                  image: Man2,
                },
              ],
            })}
          </div>
        </ChartWrapper>
      </ChartSection>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding: 2rem;
  background: #f5f5f5;
`;

const ChartSection = styled.section`
  margin-bottom: 4rem;
  margin-top: 5rem;
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  
  .org-chart {
    display: flex;
    justify-content: center;
    padding: 1rem;
    
    ul {
      display: flex;
      padding: 20px 0;
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        border-left: 2px solid #666;
        width: 0;
        height: 20px;
      }
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 0 0.5rem;
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        width: 50%;
        height: 20px;
        border-top: 2px solid #666;
      }
      
      &::after {
        left: 50%;
        border-left: 2px solid #666;
      }
      
      &:first-child::before,
      &:last-child::after {
        display: none;
      }
    }
  }

  .org-chart__item {
    border: 2px solid #0066cc;
    border-radius: 8px;
    padding: 0.75rem;
    margin: 0 0.5rem;
    min-width: 180px;
    max-width: 200px;
    background: white;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    
    .image-wrapper {
      width: 50px;
      height: 50px;
      margin: 0 auto 0.5rem;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #0066cc;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    .name {
      margin: 0;
      font-size: 0.85rem;
      font-weight: 600;
      color: #333;
    }
    
    .title {
      margin: 0.25rem 0 0;
      font-size: 0.75rem;
      color: #666;
      font-style: italic;
    }
  }
`;

export default DepartmentalCharts;