// ProjectBox.js - Create this component first
import React from "react";
import styled from "styled-components";
import { FaDownload } from 'react-icons/fa';

export default function ProjectPageProjectBox({ imageUrl, title, description, cost, date }) {
  // Truncate description to 150 characters
  const truncatedDescription = description?.length > 150 
    ? `${description.substring(0, 150)}...` 
    : description;

  return (
    <BoxWrapper>
      {/* <ImageWrapper>
        <img src={imageUrl} alt={title} />
      </ImageWrapper> */}
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{truncatedDescription}</Description>
        <MetaWrapper>
          <Cost>Cost: {cost || 'Unknown'}</Cost>
          <Date>{date || 'Unknown date'}</Date>
        </MetaWrapper>
        <DownloadButtonWrapper>
          <DownloadButton>
            <FaDownload /> Download
          </DownloadButton>
        </DownloadButtonWrapper>
      </ContentWrapper>
    </BoxWrapper>
  );
}

const BoxWrapper = styled.div`
  width: 100%;
  // height: 450px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  // height: 200px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContentWrapper = styled.div`
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #667140;
  margin-bottom: 0.75rem;
  line-height: 1.4;
`;

const Description = styled.p`
  font-size: 0.9rem;
  color: #827c63;
  line-height: 1.6;
  margin-bottom: 1rem;
  flex: 1;
`;

const MetaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Cost = styled.span`
  font-size: 0.875rem;
  color: #827c63;
`;

const Date = styled.span`
  font-size: 0.875rem;
  color: #827c63;
`;

const DownloadButtonWrapper = styled.div`
  margin-top: auto;
`;

const DownloadButton = styled.button`
  width: 100%;
  background-color: #a59650;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #667140;
  }
`;