import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { FaList, FaThLarge, FaSearchDollar, FaDownload, FaTimes, FaEnvelope, FaMapMarkerAlt, FaCalendarAlt, FaFileAlt } from 'react-icons/fa';
import { getVacancies } from "store/actions/cmsActions"; 

const VacanciesPage = () => {
  const dispatch = useDispatch();
  const { vacancys } = useSelector((state) => state.cmsReducer); // Note spelling
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';
  const [viewMode, setViewMode] = useState('grid');
  const [selectedVacancy, setSelectedVacancy] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getVacancies(organizationId));
  }, [dispatch]);
  
  console.log("Vacancies:", vacancys); // Check if it updates

  const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'grid' ? 'list' : 'grid');
  };

  const openModal = (vacancy) => {
    setSelectedVacancy(vacancy);
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto'; // Enable scrolling when modal is closed
  };

  const handleDownload = (url) => {
    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = url.substring(url.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Helper function to format date
  const formatDate = (timestamp) => {
    return timestamp 
      ? new Date(timestamp * 1000).toLocaleDateString('en-US', {
          year: 'numeric', 
          month: 'long', 
          day: 'numeric'
        }) 
      : 'Not specified';
  };

  return (
    <>
      <Header>
        <HeaderTitle>Job Vacancies</HeaderTitle>
        <HeaderSubtitle>
          Explore career opportunities with our organization. Check back regularly for new openings.
        </HeaderSubtitle>
      </Header>
      <ContentWrapper>
        <Container>
          {vacancys && vacancys.length > 0 ? (
            <>
              <ControlsContainer>
                <ViewToggle onClick={toggleViewMode}>
                  {viewMode === 'grid' ? <FaList /> : <FaThLarge />}
                  <span>{viewMode === 'grid' ? ' List View' : ' Grid View'}</span>
                </ViewToggle>
              </ControlsContainer>
              
              {viewMode === 'grid' ? (
                <VacancyGrid>
                  {vacancys.map((vacancy) => (
                    <VacancyCard key={vacancy.id}>
                      <VacancyContent>
                        <VacancyTitle>{vacancy.title}</VacancyTitle>
                        <VacancyDescription>
                          {vacancy.description?.length > 150
                            ? `${vacancy.description.substring(0, 150)}...`
                            : vacancy.description}
                        </VacancyDescription>
                        <VacancyInfo>
                          <span>Posted: {formatDate(vacancy.datePosted)}</span>
                          <DetailsButton onClick={() => openModal(vacancy)}>
                            View Details
                          </DetailsButton>
                        </VacancyInfo>
                      </VacancyContent>
                    </VacancyCard>
                  ))}
                </VacancyGrid>
              ) : (
                <TableWrapper>
                  <ResponsiveTable>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Date Posted</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vacancys.map((vacancy) => (
                        <tr key={vacancy.id}>
                          <td data-label="Title">{vacancy.title}</td>
                          <td data-label="Description">
                            {vacancy.description?.length > 100
                              ? `${vacancy.description.substring(0, 100)}...`
                              : vacancy.description}
                          </td>
                          <td data-label="Date Posted">{formatDate(vacancy.datePosted)}</td>
                          <td data-label="Action">
                            <ActionButtonsContainer>
                              <ActionButton onClick={() => openModal(vacancy)}>
                                View Details
                              </ActionButton>
                              {vacancy.documentsLinks && vacancy.documentsLinks.length > 0 && (
                                <DownloadButton onClick={() => handleDownload(vacancy.documentsLinks[0])}>
                                  <FaDownload /> Apply
                                </DownloadButton>
                              )}
                            </ActionButtonsContainer>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ResponsiveTable>
                </TableWrapper>
              )}
            </>
          ) : (
            <NoVacanciesMessage>
              <FaSearchDollar size={50} />
              <h2>No Current Vacancies</h2>
              <p>We don't have any open positions at the moment. Please check back later for future opportunities.</p>
            </NoVacanciesMessage>
          )}
        </Container>
      </ContentWrapper>

      {/* Vacancy Details Modal */}
      {isModalOpen && selectedVacancy && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalHeader>
              <ModalTitle>{selectedVacancy.title}</ModalTitle>
              <CloseButton onClick={closeModal}>
                <FaTimes />
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              <ModalSection>
                <SectionTitle>Description</SectionTitle>
                <p>{selectedVacancy.description}</p>
              </ModalSection>

              {selectedVacancy.enquiries && (
                <ModalSection>
                  <SectionTitle>Enquiries</SectionTitle>
                  <div dangerouslySetInnerHTML={{ __html: selectedVacancy.enquiries }} />
                </ModalSection>
              )}

              <ModalInfoGrid>
                {selectedVacancy.contactEmail && (
                  <ModalInfoItem>
                    <InfoIcon><FaEnvelope /></InfoIcon>
                    <div>
                      <InfoLabel>Contact Email</InfoLabel>
                      <InfoValue>{selectedVacancy.contactEmail}</InfoValue>
                    </div>
                  </ModalInfoItem>
                )}

                {selectedVacancy.location && (
                  <ModalInfoItem>
                    <InfoIcon><FaMapMarkerAlt /></InfoIcon>
                    <div>
                      <InfoLabel>Location</InfoLabel>
                      <InfoValue>{selectedVacancy.location}</InfoValue>
                    </div>
                  </ModalInfoItem>
                )}

                {selectedVacancy.datePosted && (
                  <ModalInfoItem>
                    <InfoIcon><FaCalendarAlt /></InfoIcon>
                    <div>
                      <InfoLabel>Date Posted</InfoLabel>
                      <InfoValue>{formatDate(selectedVacancy.datePosted)}</InfoValue>
                    </div>
                  </ModalInfoItem>
                )}

                {selectedVacancy.applicationDeadline && (
                  <ModalInfoItem>
                    <InfoIcon><FaCalendarAlt /></InfoIcon>
                    <div>
                      <InfoLabel>Application Deadline</InfoLabel>
                      <InfoValue>{formatDate(selectedVacancy.applicationDeadline)}</InfoValue>
                    </div>
                  </ModalInfoItem>
                )}

                {selectedVacancy.jobType && (
                  <ModalInfoItem>
                    <InfoIcon><FaFileAlt /></InfoIcon>
                    <div>
                      <InfoLabel>Job Type</InfoLabel>
                      <InfoValue>{selectedVacancy.jobType}</InfoValue>
                    </div>
                  </ModalInfoItem>
                )}

                {selectedVacancy.salaryRange && (
                  <ModalInfoItem>
                    <InfoIcon><FaSearchDollar /></InfoIcon>
                    <div>
                      <InfoLabel>Salary Range</InfoLabel>
                      <InfoValue>{selectedVacancy.salaryRange}</InfoValue>
                    </div>
                  </ModalInfoItem>
                )}
              </ModalInfoGrid>

              {selectedVacancy.qualifications && selectedVacancy.qualifications.length > 0 && (
                <ModalSection>
                  <SectionTitle>Qualifications</SectionTitle>
                  <ul>
                    {selectedVacancy.qualifications.map((qualification, index) => (
                      <li key={index}>{qualification}</li>
                    ))}
                  </ul>
                </ModalSection>
              )}

              {selectedVacancy.documentsLinks && selectedVacancy.documentsLinks.length > 0 && (
                <ModalSection>
                  <SectionTitle>Application Documents</SectionTitle>
                  <DocumentsContainer>
                    {selectedVacancy.documentsLinks.map((link, index) => (
                      <DocumentButton key={index} onClick={() => handleDownload(link)}>
                        <FaDownload /> Download Document {index + 1}
                      </DocumentButton>
                    ))}
                  </DocumentsContainer>
                </ModalSection>
              )}
            </ModalBody>
            <ModalFooter>
              {selectedVacancy.documentsLinks && selectedVacancy.documentsLinks.length > 0 && (
                <PrimaryButton onClick={() => handleDownload(selectedVacancy.documentsLinks[0])}>
                  <FaDownload /> Download Application Form
                </PrimaryButton>
              )}
              <SecondaryButton onClick={closeModal}>Close</SecondaryButton>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

const colors = {
  primary: '#8c9c6c',
  secondary: '#a59650',
  tertiary: '#667140',
  neutral: '#827c63',
  light: '#f5f5f5'
};

const ContentWrapper = styled.div`
  padding: 2rem 0;
`;

const Header = styled.header`
  background-color: ${colors.light};
  color: ${colors.tertiary};
  padding-top: 7rem;
  padding-bottom: 2rem;
  text-align: center;
  margin-top: 5rem;
  border-bottom: 2px solid ${colors.primary};
`;

const HeaderTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.tertiary};
`;

const HeaderSubtitle = styled.p`
  font-size: 1rem;
  color: ${colors.neutral};
  max-width: 800px;
  margin: 0 auto;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const ViewToggle = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const VacancyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const VacancyCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const VacancyContent = styled.div`
  padding: 1rem;
`;

const VacancyTitle = styled.h3`
  font-size: 1.25rem;
  color: ${colors.tertiary};
  margin-bottom: 0.5rem;
`;

const VacancyDescription = styled.p`
  color: ${colors.neutral};
  margin-bottom: 1rem;
  font-size: 0.9rem;
`;

const VacancyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: ${colors.neutral};
  margin-top: 1rem;
`;

const DetailsButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;

  th, td {
    padding: 1rem;
    text-align: left;
  }

  th {
    background-color: #8c9c6c;
    color: white;
    font-weight: 500;
    white-space: nowrap;
  }

  tr {
    border-bottom: 1px solid #eee;
  }

  tr:hover {
    background-color: #f8f8f8;
  }

  @media screen and (max-width: 768px) {
    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 1rem;
      border: 1px solid #eee;
    }

    td {
      display: block;
      text-align: right;
      padding: 0.5rem 1rem;
      
      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #667140;
      }
      
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const ActionButton = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const DownloadButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const NoVacanciesMessage = styled.div`
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.tertiary};
    margin: 1rem 0;
  }

  p {
    color: ${colors.neutral};
  }

  svg {
    color: ${colors.secondary};
  }
`;

// Modal Styles
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: ${colors.tertiary};
  font-size: 1.5rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  color: ${colors.neutral};
  
  &:hover {
    color: ${colors.tertiary};
  }
`;

const ModalBody = styled.div`
  padding: 1.5rem;
  overflow-y: auto;
`;

const ModalSection = styled.div`
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h3`
  color: ${colors.secondary};
  font-size: 1.1rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5rem;
`;

const ModalInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const ModalInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const InfoIcon = styled.div`
  color: ${colors.primary};
  font-size: 1.25rem;
`;

const InfoLabel = styled.div`
  font-size: 0.8rem;
  color: ${colors.neutral};
`;

const InfoValue = styled.div`
  font-size: 1rem;
  color: #333;
`;

const DocumentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const DocumentButton = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const ModalFooter = styled.div`
  padding: 1rem 1.5rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const PrimaryButton = styled.button`
  background-color: ${colors.secondary};
  color: white;
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;

  &:hover {
    background-color: ${colors.tertiary};
  }
`;

const SecondaryButton = styled.button`
  background-color: #f5f5f5;
  color: ${colors.neutral};
  border: 1px solid #ddd;
  padding: 0.75rem 1.25rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #eee;
  }
`;

export default VacanciesPage;